// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';
import 'flickity-as-nav-for';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';


$(document).ready(() => {
    // ----------------------------------------
    // GLOBAL SCRIPTS (header/footer, etc)
    // ----------------------------------------

    $('.navbar-toggler').on('click touchstart', (e) => {
        e.preventDefault();

        $('html').toggleClass('nav-open');
    });

    // let timer;

    // $('.dropdown').hover(

    //     function() {
    //         clearTimeout(timer);
    //         openSubmenu($(this).find('.dropdown-menu'));
    //     },
    //     function() {
    //         const x = $(this);
    //         timer = setTimeout(() => {
    //             x.find('.dropdown-menu').removeClass('show');
    //         }, 500);
    //     },
    // );

    // function openSubmenu(menu) {
    //     menu.addClass('show');
    // }

    // $('.accordion').accordion({
    //     transitionSpeed: 400,
    // });
});

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
    },

    data() {
        return {

        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });

    },
}).mount('#app');


// ----------------------------------------
// THIRD-PARTY PLUGINS
// ----------------------------------------
//
// All third-party plugins should be renamed with an '_' to prevent seperate file being generated.
//
// The below is a list of commonly used plugins used for many projects. However, you should only
// include the libraries you need for your site. By default, most libraries are excluded.
// To add a third-party library to the project, add a '@' symbol to the 'codekit-prepend' line.
//

// General
// @codekit-prepend "vendor/_lazysizes.js"          // Lazy-loading images
// @codekit-prepend "vendor/_ls.unveilhooks.js"     // BG support for lazy-loading images
// @codekit-prepend "vendor/_flickity.js"           // Slider
// @codekit-prepend "vendor/_accordion.js"          // Accordion

// $(document).ready(function() {
//     // Fixes initial transitions firing on page load
//     $('body').removeClass('preload');

//     // ----------------------------------------
//     // THIRD-PARTY PLUGIN CONFIG
//     // ----------------------------------------

//     $('.main-carousel').flickity({
//         accessibility: true,
//         cellAlign: 'center',
//         autoPlay: 6000,
//         selectedAttraction: 0.01,
//         friction: 0.25,
//         contain: true,
//         imagesLoaded: true,
//         prevNextButtons: false,
//         wrapAround: true,
//         lazyLoad: 2,
//         pageDots: false,
//     });

//     $(".secondary-carousel").flickity({
//         asNavFor: ".main-carousel",
//         contain: true,
//         pageDots: false,
//         prevNextButtons: false,
//         cellAlign: "left",
//         imagesLoaded: true,
//     });

//     // ----------------------------------------
//     // GLOBAL SCRIPTS (header/footer, etc)
//     // ----------------------------------------

//     $('.navbar-toggler').on('click touchstart', function(e) {
//         e.preventDefault();

//         $('html').toggleClass('nav-open');
//     });

//     // var timer;

//     // $(".dropdown").hover(

//     //     function() {
//     //         clearTimeout(timer);
//     //         openSubmenu($(this).find(".dropdown-menu"));
//     //     },
//     //     function() {
//     //         var x = $(this);
//     //         timer = setTimeout(function () {
//     //             x.find(".dropdown-menu").removeClass('show');
//     //         }, 500);
//     //     }
//     // );

//     // function openSubmenu(menu) {
//     //     menu.addClass('show');
//     // }

//     $('.accordion').accordion({
//         "transitionSpeed": 400
//     });
// });
